import React, { useState, useEffect, useCallback } from "react";
import Swiper from 'swiper';
import { useQuery } from "react-apollo";
import { NFTS_HIGHLIGHTS } from "../apollo/queries";
import { isBlocklisted } from "../helpers";
import Loader from "../helpers/Loader";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import highlightsdata from '../assets/highlights.json';


function Highlights(props) {

    let [allNfts, setAllNfts] = useState([]);
    let [nfts, setNfts] = useState({});

    const { loading, error, data, fetchMore } = useQuery(NFTS_HIGHLIGHTS, {
        variables: {
            first: 1000,
            skip: 0
        },
    });

    const getMetadata = async (jsonURL) => {
        const response = await fetch("https://ipfs.io/ipfs/" + jsonURL);
        const data = await response.json();
        return data;
    };

    useEffect(() => {
        var swiper = new Swiper('.highlight-nft .swiper-container', {
            slidesPerView: 4,
            touchRatio: 0,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                1280: {
                    slidesPerView: 4,

                },
                972: {
                    slidesPerView: 3,

                },
                700: {
                    slidesPerView: 2,

                },
                480: {
                    slidesPerView: 1,

                }
            }
        });
    });

    const getNfts = (data) => {
        setAllNfts([...allNfts, ...data]);
        data.forEach(async (nft) => {
            if (isBlocklisted(nft.jsonUrl)) return;
            let _nft = nft;
            _nft.metadata = await getMetadata(nft.jsonUrl);
            let _newNft = {};
            _newNft[_nft.nftNumber] = _nft;
            setNfts((nfts) => ({ ...nfts, ..._newNft }));
            //setNfts((nfts) => [...nfts, _nft]);
        });
    };

    function LoadingMore() {
        return <div className="spinner-grow text-light m-5 justify-content-center" role="status">
            <span className="sr-only"></span> <br /> <br />Loading...</div>
    }


    const onLoadMore = useCallback(() => {
        if (
            Math.round(window.scrollY + window.innerHeight) >=
            Math.round(document.body.scrollHeight)
        ) {
            fetchMore({
                variables: {
                    skip: allNfts.length,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return fetchMoreResult;
                },
            });
        }
    }, [fetchMore, allNfts.length]);

    useEffect(() => {
        data ? getNfts(data.nfts) : console.log("loading");
    }, [data]);

    useEffect(() => {
        window.addEventListener("scroll", onLoadMore);
        return () => {
            window.removeEventListener("scroll", onLoadMore);
        };
    }, [onLoadMore]);


    if (loading) return <Loader />;
    if (error) return `Error! ${error.message}`;



    return (
        <div>

            <h3>&nbsp;Highlights</h3>

            <div className="highlight-nft">
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        {nfts
                            ? Object.keys(nfts)
                                .sort(function(a,b){return nfts[b].likeCount-nfts[a].likeCount})
                                .map((nft) => (
                                    <div className="swiper-slide">
                                        <div className="card nft-item">



                                            <div className="card-body" style={{ cursor: 'pointer', width: "13em", marginLeft: "auto", marginRight: "auto" }}>
                                                <Link to={"assets/" + nfts[nft].id} style={{ textDecoration: 'none' }}>
                                                    <LazyLoadImage
                                                        style={{ cursor: 'pointer' }}
                                                        className="view"
                                                        src={nfts[nft].metadata.image.replace("ipfs.io", "ipfs.nftyard.io")}
                                                        alt={nfts[nft].metadata.name}
                                                        layout="fill"
                                                    />
                                                </Link>
                                            </div>
                                            <div className="card-footer">
                                                <Link href={{
                                                    pathname: "/ViewNft",
                                                    query: { hash: nfts[nft].id },
                                                }}>
                                                    <h5 style={{ cursor: 'pointer' }}>
                                                        {nfts[nft].metadata.name.length > 18
                                                            ? nfts[nft].metadata.name.slice(0, 16).concat("...")
                                                            : nfts[nft].metadata.name}
                                                    </h5>
                                                </Link>
                                                {nfts[nft].bestPrice > 0 ? (
                                                    <>
                                                        <span>
                                                            <img src="https://gateway.pinata.cloud/ipfs/QmbYA7QhqFTLzVSAzE9ymfVvdE1hwBskmXE4X4EK9FevMa" alt="MATIC" />
                                                            {nfts[nft].bestPrice / 1e18}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span>
                                                            <img src="https://gateway.pinata.cloud/ipfs/QmbYA7QhqFTLzVSAzE9ymfVvdE1hwBskmXE4X4EK9FevMa"
                                                                alt="MATIC"
                                                                style={{ marginLeft: 5, width: 20, visibility: "hidden" }} />
                                                            ?
                                                        </span>
                                                    </>
                                                )}

                                            </div>


                                        </div>
                                    </div>
                                ))
                            : null}
                    </div>
                    <div className="swiper-button-next">
                        <i className="fas fa-chevron-right"></i>
                    </div>
                    <div className="swiper-button-prev">
                        <i className="fas fa-chevron-left"></i>
                    </div>
                </div>
            </div>




        </div>
    )
}

export default Highlights